import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { SecondaryButton } from '@nib-components/button';
import { Bold } from '@nib-components/copy';
import Expander from '@nib-components/expander';
import Heading from '@nib-components/heading';
import { colorTrueGreen } from '@nib-components/theme';
import { ChevronDownSystemIcon, ChevronUpSystemIcon, IconType } from '@nib/icons';
import { Box } from '@nib/layout';
import { flatMap } from 'lodash';
import React, { useState } from 'react';
import { Insight, InsightEntry, Recommendation } from '../../pages/healthcheck/results';
import { switchBreakpoint, useBreakpoint } from '../../utils/breakpoints';
import { reactOptions } from '../../utils/reactOptions';
import { generateDynamicIcon } from '../GraphicIcons';
import Recommendations from '../Recommendations';

interface InsightsProps {
  category: Insight[];
  categoryName: string;
  icon: IconType;
}

/**
 * Displays the icon, title and main content for an insight's entries.
 */

const Insights = (props: InsightsProps) => {
  const { category, categoryName, icon } = props;
  const [expanded, setExpanded] = useState(false);

  // Use breakpoint hook to allow changing attributes that don't support Mesh's breakpoint objects
  const breakpoint = useBreakpoint();

  // Get all insight entries from all insights
  const insightEntries: InsightEntry[] = flatMap(category, (insight) => insight.insightEntries);

  // If we have none, skip this one
  if (insightEntries.length === 0) {
    return null;
  }

  // Get all recommendations from all insights
  const recommendations: Recommendation[] = flatMap(category, (item) => item.recommendations);

  return (
    <Box display="block" marginBottom={6} width="100%">
      <Box
        style={{
          position: switchBreakpoint(breakpoint, { xs: 'inherit', md: 'absolute' }),
        }}
      >
        {generateDynamicIcon(icon)}
      </Box>
      <Box display="block" paddingLeft={{ xs: 0, md: 9, lg: 10 }} marginLeft={{ xs: 0, lg: 2 }}>
        <Box marginBottom={4}>
          <Heading color={colorTrueGreen} component="h4" size={{ xs: 4, md: 3 }}>
            {categoryName}
          </Heading>
        </Box>

        {insightEntries?.slice(0, 2).map((item, index) => {
          return (
            <Box key={`entry-${categoryName}-${index}`} marginBottom={4}>
              <Box marginBottom={4}>
                <Bold data-testid={`entry-title-${index}`} style={{ display: 'block' }}>
                  {item.title}
                </Bold>
              </Box>
              <Box data-testid={`entry-content-${index}`}>
                {item.content && documentToReactComponents(item.content, reactOptions())}
              </Box>
            </Box>
          );
        })}
        {insightEntries.length > 2 && (
          <>
            <Expander expanded={expanded}>
              {insightEntries.slice(2).map((item, index) => {
                return (
                  <Box key={`entry-${categoryName}-${index + 2}`} marginBottom={4}>
                    <Box marginBottom={4}>
                      <Bold data-testid={`entry-title-${index + 2}`} style={{ display: 'block' }}>
                        {item.title}
                      </Bold>
                    </Box>
                    <Box data-testid={`entry-content-${index + 2}`}>
                      {item.content && documentToReactComponents(item.content, reactOptions())}
                    </Box>
                  </Box>
                );
              })}
            </Expander>
            <Box marginTop={4} marginBottom={6}>
              <SecondaryButton
                icon={expanded ? ChevronUpSystemIcon : ChevronDownSystemIcon}
                onClick={() => setExpanded(!expanded)}
              >
                {expanded ? 'Read less' : 'Read more'}
              </SecondaryButton>
            </Box>
          </>
        )}
        {recommendations?.length > 0 && (
          <Box marginBottom={4}>
            <Recommendations recommendations={recommendations} categoryName={categoryName} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Insights;
