import axios from 'axios';
import React from 'react';
import pRetry, { AbortError } from 'p-retry';
import config from '../../utils/env';

/**
 * The response shape of the API
 */
export interface GoodHealthPlanResponse {
  insights: GoodHealthPlanInsight[];
  customerData: {
    customerId: string;
    weight: number;
    height: number;
    units: string;
    age: number;
    bmi: number;
    smoking_status: string;
    drug: string;
    significantMhDistress: number;
  };
  completedAt: string;
}

interface GoodHealthPlanInsight {
  domain: string;
  priority: number;
  code: string;
  text: string;
  recommendationType: string;
  compliant: string;
  mandatory: boolean;
  reference: {
    name: string;
    link?: string;
    section?: string;
  }[];
  baseRecommendation: string[];
}

// Function generator to retrieve insight IDs
const getHealthCheckInsightIdFetcher =
  (resultsId: string) => async (): Promise<GoodHealthPlanResponse> => {
    const response = await axios.post<GoodHealthPlanResponse>(
      config.healthCheckApiUrl,
      { id: resultsId, geo: 'nz' },
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'x-api-key': config.healthCheckApiKey,
        },
        timeout: 5000,
      }
    );
    if (response.status !== 200) {
      throw new AbortError(response.statusText);
    }
    return response.data;
  };

/**
 * React hook to fetch a list of Insight IDs from Honeysuckle Health using a token, for use
 * with the HealthCheck Results page.
 */
export const useGetHealthCheckInsightIds = (id: string) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isError, setIsError] = React.useState<boolean>(false);
  const [data, setData] = React.useState<string[] | undefined>();

  if (!config.healthCheckApiUrl) {
    throw new Error(
      'Config error: `healthCheckApiUrl` is not defined, check `src/config-override.json` or SSM parameters'
    );
  }

  React.useEffect(() => {
    setData(undefined);

    // If we didn't supply a token, jump straight to error state.
    const isValid = id !== '';
    setLoading(isValid);
    setIsError(!isValid);
    if (!isValid) {
      return;
    }

    // If we have a token, fetch the Insight Ids for it.
    const fn = getHealthCheckInsightIdFetcher(id);
    pRetry(fn, { retries: 5 })
      .then((data) => {
        // Convert response to list of codes
        setData(data.insights.map((insight) => insight.code));
      })
      .catch((_err) => {
        setIsError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  return { loading, isError, data };
};
