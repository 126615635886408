import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import Copy, { Bold } from '@nib-components/copy';
import React from 'react';

/**
 * Render Contentful Rich Text content using Mesh components.
 *
 * Usage:
 *
 *     import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
 *     import { Document } from '@contentful/rich-text-react-renderer/node_modules/@contentful/rich-text-types';
 *
 *     const MyComponent = ({content: Document}) => {
 *         return documentToReactComponents(content, reactOptions())
 *     }
 */
export const reactOptions = () => {
  return {
    renderMark: {
      [MARKS.BOLD]: (text) => (
        <Copy>
          <Bold>{text}</Bold>
        </Copy>
      ),
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Copy>{children}</Copy>,
    },
  };
};
