import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { PrimaryButton, SecondaryButton } from '@nib-components/button';
import Copy, { Bold } from '@nib-components/copy';
import Expander from '@nib-components/expander';
import Heading from '@nib-components/heading';
import { colorTrueGreen } from '@nib-components/theme';
import { ChevronDownSystemIcon, ChevronUpSystemIcon } from '@nib/icons';
import { Box } from '@nib/layout';
import { UtilityButtonProps } from '@nib/utility-button';
import { uniqBy } from 'lodash';
import React from 'react';
import { Recommendation } from '../../pages/healthcheck/results';
import { reactOptions } from '../../utils/reactOptions';

/**
 * Displays the "Recommended for you" box.
 *
 * If there are more than two results, has an expander that lets the user see the rest.
 */

const Recommendations = (props: { recommendations: Recommendation[]; categoryName: string }) => {
  const { recommendations, categoryName } = props;
  const [expanded, setExpanded] = React.useState(false);

  // Deduplicate by title
  const filteredRecommendations = uniqBy(recommendations, (r) => r.title);

  return (
    <div style={{ maxWidth: '610px' }}>
      <Box
        paddingHorizontal={{ xs: 5, md: 6 }}
        paddingTop={5}
        paddingBottom={2}
        background="sageGreen20"
      >
        <Box marginBottom={4}>
          <Heading color={colorTrueGreen} component="h5" size={{ xs: 4, md: 3 }}>
            Recommended for you
          </Heading>
        </Box>
        {filteredRecommendations.slice(0, 2).map((recommendation, index) => (
          <RecommendationEntry
            key={`recommendation-${categoryName}-${index}`}
            recommendation={recommendation}
            index={index}
          />
        ))}
        {filteredRecommendations.length > 2 && (
          <Box>
            <Expander expanded={expanded}>
              {filteredRecommendations.slice(2).map((recommendation, index) => (
                <RecommendationEntry
                  key={`recommendation-${categoryName}-${index + 2}`}
                  recommendation={recommendation}
                  index={index + 2}
                />
              ))}
            </Expander>
            <Box marginBottom={4}>
              <SecondaryButton
                icon={expanded ? ChevronUpSystemIcon : ChevronDownSystemIcon}
                onClick={() => setExpanded(!expanded)}
              >
                {expanded ? 'View less' : 'More recommendations'}
              </SecondaryButton>
            </Box>
          </Box>
        )}
      </Box>
    </div>
  );
};

const RecommendationEntry = ({
  recommendation,
  index,
}: {
  recommendation: Recommendation;
  index: number;
}) => {
  const { title, content, buttons } = recommendation;
  return (
    <Box marginBottom={4} key={`recommendation-${index}`}>
      <Box marginBottom={4}>
        <Copy color={colorTrueGreen}>
          <Bold data-testid={`recommendation-title-${index}`}>{title}</Bold>
        </Copy>
      </Box>
      <Box data-testid={`recommendation-content-${index}`} marginBottom={4}>
        {content && documentToReactComponents(content, reactOptions({ entryType: 'cta' }))}
      </Box>
      {buttons.map((button, j) => (
        <Box key={`recommendation-button-${index}-${j}`} marginBottom={5}>
          <RecommendationUtilityButton {...button} />
        </Box>
      ))}
    </Box>
  );
};

const RecommendationUtilityButton = (props: UtilityButtonProps) => (
  <PrimaryButton target="_blank" color="dark" role="button" href={props.url} title={props.subtitle}>
    {props.title}
  </PrimaryButton>
);

export default Recommendations;
