import { breakpoints as breakpointsMap } from '@nib-components/theme';
import { useEffect, useState } from 'react';

export type Breakpoint = keyof typeof breakpointsMap;

/**
 * React hook to get the current breakpoint. Useful in conjunction with `switchBreakpoint` for parameters that don't support Mesh's
 * breakpoint objects eg. `attribute={{sm: 1, md: 2, lg: 3}}`
 * Usage:
 *
 *     const breakpoint: Breakpoint = useBreakpoint();
 *     return <MyComponent attribute={switchBreakpoint(breakpoint, {sm: 4, md: 6, lg: 8})} />;
 */
export function useBreakpoint() {
  const [breakpoint, setBreakpoint] = useState(getBreakpoint());

  useEffect(() => {
    const handleResize = () => {
      setBreakpoint(getBreakpoint());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return breakpoint;
}

/**
 * Function to return a value based on a map of breakpoints to values.
 * Will return
 * Usage:
 *
 *     <MyComponent attribute={switchBreakpoint(breakpoint, {sm: 4, md: 6, lg: 8})} />
 */
export function switchBreakpoint<T>(
  breakpoint: Breakpoint,
  map: Partial<Record<Breakpoint, T>>
): T {
  const currentBreakpointWidth: number = breakpointsMap[breakpoint];
  let smallest: Breakpoint | undefined = undefined;
  let match: Breakpoint | undefined = undefined;
  let a: Breakpoint;
  for (a in map) {
    // Find the smallest breakpoint specified in the map
    if (smallest === undefined || breakpointsMap[a] < breakpointsMap[smallest]) {
      smallest = a;
    }
    // Find the largest breakpoint that is smaller than or equal to our current breakpoint
    if (
      (match === undefined || breakpointsMap[a] > breakpointsMap[match]) &&
      breakpointsMap[a] <= currentBreakpointWidth
    ) {
      match = a;
    }
  }
  // If we found a breakpoint, return our value
  if (match !== undefined) {
    return map[match] as T;
  }
  // If nothing is found less than our current width, we need the smallest one
  if (smallest !== undefined) {
    return map[smallest] as T;
  }
  throw new Error('Did not specify any breakpoints.');
}

/**
 * Get the current breakpoint
 */
function getBreakpoint(): Breakpoint {
  let width: number = 0;
  if (typeof window !== `undefined`) {
    width = window.innerWidth;
  }
  let match: Breakpoint | undefined;
  let a: Breakpoint;
  for (a in breakpointsMap) {
    // Find the largest breakpoint that is smaller than or equal to our current width
    if (
      (match === undefined || breakpointsMap[a] > breakpointsMap[match]) &&
      breakpointsMap[a] <= width
    ) {
      match = a;
    }
  }
  // If we found a breakpoint, return our value
  if (match !== undefined) {
    return match;
  }
  // If nothing is found less than our current width, we're at max
  return 'xxxl';
}
