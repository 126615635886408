import { Document } from '@contentful/rich-text-react-renderer/node_modules/@contentful/rich-text-types';
import { SecondaryButton } from '@nib-components/button';
import Copy, { Bold } from '@nib-components/copy';
import Heading from '@nib-components/heading';
import { Tab, TabList, TabPanel, Tabs } from '@nib-components/tabs';
import { colorBrandBase } from '@nib-components/theme';
import { ChevronLeftSystemIcon, ChevronRightSystemIcon } from '@nib/icons';
import { Box, Stack } from '@nib/layout';
import Loader from '@nib/loader';
import { UtilityButtonProps } from '@nib/utility-button';
import { PageProps, graphql } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import HeroPanel from '../../components/HeroPanel';
import Insights from '../../components/Insights';
import Layout from '../../components/Layout';
import { useGetHealthCheckInsightIds } from '../../components/shared/HealthCheckResultsApiService';
import { HeroPanelCopy } from '../../components/styledComponents';
import { insightThemesAndCategories } from '../../content/healthcheck';
import metrics from '../../metrics';

// Hero images
import hero_tablet2x from '../../img/healthcheck/hero/1600x580.jpg';
import hero_desktop from '../../img/healthcheck/hero/2000x600.jpg';
import hero_desktop2x from '../../img/healthcheck/hero/4000x1200.jpg';
import hero_mobile from '../../img/healthcheck/hero/400x150.jpg';
import hero_tablet from '../../img/healthcheck/hero/800x290.jpg';
import hero_mobile2x from '../../img/healthcheck/hero/800x300.jpg';

const heroImages = {
  mobile: hero_mobile,
  mobile2x: hero_mobile2x,
  tablet: hero_tablet,
  tablet2x: hero_tablet2x,
  desktop: hero_desktop,
  desktop2x: hero_desktop2x,
};

const TABLIST_ID = 'tablist';

const title = 'HealthCheck';
const description = 'Better understand your health and wellbeing.';

export type InsightCategoryType =
  | 'ALC'
  | 'BMI'
  | 'DRUGS'
  | 'EXERCISE'
  | 'FALLS'
  | 'MH'
  | 'NUTRITION'
  | 'SCR'
  | 'SLEEP'
  | 'SMK'
  | 'VAC';

export interface InsightEntry {
  title: string;
  content: Document | null;
}

export interface Recommendation {
  title: string;
  content: Document | null;
  buttons: UtilityButtonProps[];
}

// Unused cols:
// nib theme
// User facing category
// type
export interface Insight {
  insightId: string; // col: ID
  insightPriority: number; // col: min priority
  category: InsightCategoryType | null; // col: Category
  insightEntries: InsightEntry[];
  recommendations: Recommendation[];
}

/**
 * Convert the GraphQL page query response to an array of Insight objects
 */
export const mapNodesToInsights = (node: any): Insight => {
  const insightEntries = node.insightEntries instanceof Array ? node.insightEntries : [];
  const recommendations = node.recommendations instanceof Array ? node.recommendations : [];
  return {
    insightId: node.insightId ?? '',
    insightPriority: node.insightPriority ?? 0,
    category: (node.category as InsightCategoryType) ?? null,
    insightEntries: insightEntries.map((entry) => {
      let content: Document | null = null;
      try {
        content = JSON.parse(entry?.content?.raw ?? 'null') as Document;
      } catch {
        // let content be null if unparseable
      }
      return {
        title: entry?.title ?? '',
        content,
      };
    }),
    recommendations: recommendations.map((recommendation) => {
      let content: Document | null = null;
      try {
        content = JSON.parse(recommendation?.content?.raw ?? 'null') as Document;
      } catch {
        // let content be null if unparseable
      }
      // Map JSON structure from API to array of UtilityButtonProps
      const buttons: UtilityButtonProps[] = (
        recommendation?.buttons instanceof Array ? recommendation.buttons : []
      )
        // Whole objects could be null - ignore these ones
        .filter((a) => a !== null)
        // Title/subtitle can be null
        .map((a) => ({
          ...a,
          title: a?.title ?? '',
          subtitle: a?.subtitle ?? undefined,
        }));
      return {
        title: recommendation?.title ?? '',
        content,
        buttons,
      };
    }),
  };
};

export const HealthcheckResults = (
  props: Pick<PageProps<Queries.HealthCheckResultsQuery>, 'location' | 'data'>
): JSX.Element => {
  const { location, data } = props;
  const [tabIndex, setTabIndex] = React.useState(0);

  // Get the token passed in from Honeysuckle Health
  const token: string = new URLSearchParams(location.search).get('id') ?? '';

  // Find which insight IDs we should display.
  // If no token was supplied, we jget an error.
  const { loading, isError, data: insightIds } = useGetHealthCheckInsightIds(token);

  // Convert GraphQL response to the data structure we want
  const dataContent: Insight[] = insightIds
    ? data.allContentfulHealthCheckInsight.edges
        .map((edge) => edge.node)
        // Only show codes we've included on the URL
        .filter((node) => node.insightId !== null && insightIds.includes(node.insightId))
        // Convert to array of Insights
        .map(mapNodesToInsights)
    : [];

  // Group insights by category, sort with highest insightPriority first
  const groupedCategories: Record<string, Insight[]> = dataContent
    .map((val) => val['category'])
    .reduce((acc, val, i) => {
      if (val !== null) {
        acc[val] = (acc[val] || []).concat(dataContent[i]);
      }
      return acc;
    }, {});

  Object.keys(groupedCategories).forEach((key) =>
    groupedCategories[key].sort((a, b) => b.insightPriority - a.insightPriority)
  );

  // Find the category tabs we need to display
  const themeKeys = Object.keys(insightThemesAndCategories);
  themeKeys.forEach((themeName) => {
    insightThemesAndCategories[themeName].categories.sort(
      (a, b) =>
        groupedCategories?.[b.key]?.[0]?.insightPriority -
        groupedCategories?.[a.key]?.[0]?.insightPriority
    );
  });

  const scrollToTabs = () => {
    const tablist = document.getElementById(TABLIST_ID);
    if (tablist) {
      tablist.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const next = () => {
    setTabIndex(tabIndex + 1);
    scrollToTabs();
  };

  const previous = () => {
    setTabIndex(tabIndex - 1);
    scrollToTabs();
  };

  return (
    <Layout>
      <div>
        <Helmet>
          <title>{title} | nib</title>
          <meta name="description" content={description} />
        </Helmet>
        <HeroPanel title="Your HealthCheck Results" variation="condensed" images={heroImages}>
          <HeroPanelCopy>Better understand your health and wellbeing.</HeroPanelCopy>
        </HeroPanel>
        <Box marginVertical={{ xs: 6, md: 8 }} paddingHorizontal={4}>
          <Box textAlign={{ xs: 'left', md: 'center' }} marginBottom={6}>
            <Box marginBottom={4}>
              <Heading color="trueGreen" component="h2" size={{ xs: 3, md: 2 }}>
                Understanding your health
              </Heading>
            </Box>
            <Box width="100%">
              <Copy color="darker" align={{ xs: 'left', md: 'center' }} measure={false}>
                Based on your responses, here are your health insights. You can bookmark or save
                this page for future reference.
              </Copy>
              <Copy color="darker" align={{ xs: 'left', md: 'center' }} measure={false}>
                We hope these insights help you to look after your health and wellbeing.
              </Copy>
            </Box>
          </Box>
          <Box style={{ maxWidth: '960px', margin: 'auto' }}>
            <div id={TABLIST_ID}>
              <Tabs
                forceRenderTabPanel
                selectedIndex={tabIndex}
                onSelect={(index: number) => {
                  setTabIndex(index);
                }}
              >
                <Box marginBottom={6}>
                  <TabList center tabIndex={tabIndex}>
                    {themeKeys.map((themeName, index) => {
                      const { label } = insightThemesAndCategories[themeName];
                      return <Tab key={index}>{label}</Tab>;
                    })}
                  </TabList>
                </Box>
                {themeKeys.map((themeName, index) => {
                  const { label, description, images, altText } =
                    insightThemesAndCategories[themeName];
                  return (
                    <div
                      key={index}
                      tabIndex={tabIndex}
                      style={{
                        maxWidth: '950px',
                        margin: 'auto',
                        display: tabIndex === index ? 'block' : 'none',
                      }}
                    >
                      <TabPanel tabIndex={tabIndex}>
                        <Box marginBottom={6}>
                          <Box marginBottom={4}>
                            <Heading color="trueGreen" component="h3" size={{ xs: 3, lg: 2 }}>
                              {label}
                            </Heading>
                          </Box>
                          <Box marginBottom={6} width="100%">
                            <Stack space={2}>{description}</Stack>
                          </Box>
                          <Box width="100%" align="left">
                            <StyledThemeImg images={images} role="img" aria-label={altText} />
                          </Box>
                        </Box>
                        {loading ? (
                          <Box
                            marginVertical={6}
                            display="flex"
                            justifyContent="flex-start"
                            alignItems="center"
                            flexDirection="row"
                          >
                            <Box marginRight={6}>
                              <Loader size="md" role="progressbar" />
                            </Box>
                            <Box paddingBottom={4}>
                              <Bold measure={false} color={colorBrandBase}>
                                Creating your good health plan report
                              </Bold>
                            </Box>
                          </Box>
                        ) : (
                          <>
                            {isError || Object.keys(groupedCategories).length === 0 ? (
                              <Box display="block" marginVertical={6}>
                                <Bold measure={false} color={colorBrandBase}>
                                  These insights are not available at this stage.
                                </Bold>
                              </Box>
                            ) : (
                              <Box display="block" width="100%" marginBottom={6}>
                                {insightThemesAndCategories[themeName].categories.map(
                                  (category) => (
                                    <Insights
                                      key={`insights-${category.key}`}
                                      icon={category.icon}
                                      category={groupedCategories[category.key]}
                                      categoryName={category.label}
                                    />
                                  )
                                )}
                              </Box>
                            )}
                            <Box
                              display="flex"
                              justifyContent={tabIndex === 0 ? 'flex-end' : 'space-between'}
                              flexWrap="wrap"
                              flexDirection={{ xs: 'column-reverse', md: 'row' }}
                            >
                              {tabIndex > 0 && (
                                <SecondaryButton
                                  iconPlacement="left"
                                  icon={ChevronLeftSystemIcon}
                                  onClick={previous}
                                  fullWidth={{ xs: true, md: false }}
                                >
                                  Previous
                                </SecondaryButton>
                              )}
                              {tabIndex + 1 < themeKeys.length && (
                                <Box
                                  marginBottom={{ xs: 5, md: 0 }}
                                  width={{ xs: '100%', md: 'auto' }}
                                >
                                  <SecondaryButton
                                    icon={ChevronRightSystemIcon}
                                    iconPlacement="right"
                                    onClick={next}
                                    fullWidth={{ xs: true, md: false }}
                                  >
                                    See {insightThemesAndCategories[themeKeys[tabIndex + 1]].label}
                                  </SecondaryButton>
                                </Box>
                              )}
                            </Box>
                          </>
                        )}
                      </TabPanel>
                    </div>
                  );
                })}
              </Tabs>
            </div>
          </Box>
        </Box>
      </div>
    </Layout>
  );
};

// An image that changes src responsively, and scales to fit the container it is in.
// Keeps a steady aspect ratio to match the source images.
const StyledThemeImg = styled.div`
  display: block;
  content: '';

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  width: 100%;

  background-image: url(${({ images }: any) => images && images.mobile});
  padding-top: 58.31%;

  ${breakpoint('sm')`
      background-image: url(${({ images }: any) => images && images.tablet});
      padding-top: 66.1%;
  `}
  ${breakpoint('xl')`
      background-image: url(${({ images }: any) => images && images.desktop});
      padding-top: 75%;
  `}
`;

export const query = graphql`
  query HealthCheckResults {
    allContentfulHealthCheckInsight(sort: { order: DESC, fields: contentful_id }) {
      edges {
        node {
          insightId
          insightPriority
          category
          insightEntries {
            title
            content {
              raw
            }
          }
          recommendations {
            title
            content {
              raw
            }
            buttons {
              title
              subtitle
              url
            }
          }
        }
      }
    }
  }
`;

export default metrics({ pageName: 'healthcheck-results' })(HealthcheckResults);
