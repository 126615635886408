import Copy from '@nib-components/copy';
import {
  AccidentGraphicIcon,
  AlcoholFreeGraphicIcon,
  BodyGraphicIcon,
  IconType,
  InjectionGraphicIcon,
  MindGraphicIcon,
  MovementGraphicIcon,
  PearGraphicIcon,
  PharmaceuticalGraphicIcon,
  QuitSmokingGraphicIcon,
  SleepGraphicIcon,
  WellnessScoreGraphicIcon,
  XrayGraphicIcon,
} from '@nib/icons';
import React from 'react';
import { InsightCategoryType } from '../pages/healthcheck/results';

// Emotional Wellbeing images
import ew_mobile from '../img/healthcheck/emotional-wellbeing/343x200.jpg';
import ew_tablet from '../img/healthcheck/emotional-wellbeing/590x390.jpg';
import ew_desktop from '../img/healthcheck/emotional-wellbeing/900x675.jpg';

const emotionalWellbeingImages = {
  mobile: ew_mobile,
  tablet: ew_tablet,
  desktop: ew_desktop,
};

// Fitness images
import f_mobile from '../img/healthcheck/fitness/343x200.jpg';
import f_tablet from '../img/healthcheck/fitness/590x390.jpg';
import f_desktop from '../img/healthcheck/fitness/900x675.jpg';

const fitnessImages = {
  mobile: f_mobile,
  tablet: f_tablet,
  desktop: f_desktop,
};

// Lifestyle choices images
import lc_mobile from '../img/healthcheck/lifestyle-choices/343x200.jpg';
import lc_tablet from '../img/healthcheck/lifestyle-choices/590x390.jpg';
import lc_desktop from '../img/healthcheck/lifestyle-choices/900x675.jpg';

const lifestyleChoicesImages = {
  mobile: lc_mobile,
  tablet: lc_tablet,
  desktop: lc_desktop,
};

// Preventative Health images
import ph_mobile from '../img/healthcheck/preventative-health/343x200.jpg';
import ph_tablet from '../img/healthcheck/preventative-health/590x390.jpg';
import ph_desktop from '../img/healthcheck/preventative-health/900x675.jpg';

const preventativeHealthImages = {
  mobile: ph_mobile,
  tablet: ph_tablet,
  desktop: ph_desktop,
};

export interface InsightCategory {
  key: InsightCategoryType;
  label: string;
  icon: IconType;
}

interface InsightTheme {
  categories: InsightCategory[];
  label: string;
  icon: IconType;
  description: React.ReactNode;
  images: {
    desktop: any;
    tablet: any;
    mobile: any;
  };
  altText: string;
}
export type InsightThemesAndCategories = Record<string, InsightTheme>;

// How to display HealthCheck results

export const insightThemesAndCategories: InsightThemesAndCategories = {
  healthBehaviours: {
    categories: [
      {
        key: 'EXERCISE',
        label: 'Exercise',
        icon: MovementGraphicIcon,
      },
      {
        key: 'BMI',
        label: 'BMI',
        icon: BodyGraphicIcon,
      },
      {
        key: 'NUTRITION',
        label: 'Nutrition',
        icon: PearGraphicIcon,
      },
    ],
    label: 'Fitness',
    icon: MovementGraphicIcon,
    description: <Copy>This theme focuses on various activities that affect your health.</Copy>,
    images: fitnessImages,
    altText: 'A person jogging',
  },
  lifestyleChoices: {
    categories: [
      {
        key: 'ALC',
        label: 'Alcohol',
        icon: AlcoholFreeGraphicIcon,
      },
      {
        key: 'DRUGS',
        label: 'Recreational drugs',
        icon: PharmaceuticalGraphicIcon,
      },
      {
        key: 'SMK',
        label: 'Smoking',
        icon: QuitSmokingGraphicIcon,
      },
    ],
    label: 'Lifestyle choices',
    icon: PearGraphicIcon,
    description: (
      <Copy>This theme focuses on various choices you can make that affect your health.</Copy>
    ),
    images: lifestyleChoicesImages,
    altText: 'Two people enjoying their morning coffees',
  },
  emotionalWellbeing: {
    categories: [
      {
        key: 'MH',
        label: 'Mental health',
        icon: MindGraphicIcon,
      },
      {
        key: 'SLEEP',
        label: 'Sleep',
        icon: SleepGraphicIcon,
      },
    ],
    label: 'Emotional wellbeing',
    icon: MindGraphicIcon,
    description: (
      <Copy>
        This theme will cover sleep and emotional health to assess how your health impacts you day
        to day.
      </Copy>
    ),
    images: emotionalWellbeingImages,
    altText: 'A person in bed, stretching happily after a restful sleep',
  },
  preventativeHealth: {
    categories: [
      {
        key: 'SCR',
        label: 'Health screening',
        icon: XrayGraphicIcon,
      },
      {
        key: 'VAC',
        label: 'Vaccinations',
        icon: InjectionGraphicIcon,
      },
      {
        key: 'FALLS',
        label: 'Falls',
        icon: AccidentGraphicIcon,
      },
    ],
    label: 'Preventative health',
    icon: WellnessScoreGraphicIcon,
    description: (
      <>
        <Copy>
          This theme will cover physical health and pain to assess how your health impacts you day
          to day.
        </Copy>
        <Copy>
          It also asks about preventative health activities you may have covered with your GP.
        </Copy>
      </>
    ),
    images: preventativeHealthImages,
    altText: 'A doctor showing a patient some information on a tablet',
  },
};
