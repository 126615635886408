import { colorTrueGreen } from '@nib-components/theme';
import React from 'react';
import styled, { css } from 'styled-components';
import { map } from 'styled-components-breakpoint';

const StyledDiv = styled.div`
  ${({ breakpoints }) =>
    map(
      breakpoints,
      (val) =>
        css`
          svg {
            width: ${val}px;
            height: ${val}px;
            color: ${colorTrueGreen};
          }
        `
    )};
`;

export const generateDynamicIcon = (Icon) => {
  return (
    <StyledDiv breakpoints={{ xs: 48, md: 64, lg: 96 }}>
      <Icon size="xxl" />
    </StyledDiv>
  );
};
